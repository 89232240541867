var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("app-header", {
        attrs: { title: "绑定银行账户信息", isShowBack: true }
      }),
      _c(
        "div",
        { staticClass: "base-info" },
        [
          _c("div", { staticClass: "info-title" }, [
            _vm._v(_vm._s(_vm.bindTitle))
          ]),
          _c(
            "el-form",
            {
              ref: "bindAccountForm",
              attrs: { model: _vm.bindAccountForm, rules: _vm.rules }
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    "label-width": "170px",
                    label: "开户行名称",
                    prop: "openBank"
                  }
                },
                [
                  _c(
                    "el-select",
                    {
                      ref: "select1",
                      staticStyle: { width: "420px", display: "inline-block" },
                      attrs: {
                        filterable: "",
                        remote: "",
                        "reserve-keyword": "",
                        "remote-method": _vm.remoteOpenMethod,
                        placeholder: "根据开户行名称搜索",
                        clearable: ""
                      },
                      on: {
                        change: function($event) {
                          _vm.onChangeSelectBank(_vm.bindAccountForm.openBank)
                        },
                        "hook:mounted": _vm.cancalReadOnly,
                        "visible-change": _vm.cancalReadOnly,
                        clear: function($event) {
                          _vm.handleClearBank()
                        }
                      },
                      model: {
                        value: _vm.bindAccountForm.openBank,
                        callback: function($$v) {
                          _vm.$set(_vm.bindAccountForm, "openBank", $$v)
                        },
                        expression: "bindAccountForm.openBank"
                      }
                    },
                    _vm._l(_vm.openBankList, function(it) {
                      return _c("el-option", {
                        key: it.label,
                        attrs: { label: it.label, value: it.label }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    "label-width": "170px",
                    label: "支行行名",
                    prop: "branchBank"
                  }
                },
                [
                  _c(
                    "el-select",
                    {
                      ref: "select",
                      staticStyle: { width: "420px", display: "inline-block" },
                      attrs: {
                        filterable: "",
                        remote: "",
                        "reserve-keyword": "",
                        "remote-method": _vm.remoteMethod,
                        placeholder: "根据支行行名搜索",
                        clearable: ""
                      },
                      on: {
                        "hook:mounted": _vm.cancalReadOnly,
                        "visible-change": _vm.cancalReadOnly,
                        change: function($event) {
                          _vm.onChangeSelect(_vm.bindAccountForm.branchBank)
                        },
                        clear: function($event) {
                          _vm.handleClear()
                        }
                      },
                      model: {
                        value: _vm.bindAccountForm.branchBank,
                        callback: function($$v) {
                          _vm.$set(_vm.bindAccountForm, "branchBank", $$v)
                        },
                        expression: "bindAccountForm.branchBank"
                      }
                    },
                    _vm._l(_vm.branchBankList, function(it) {
                      return _c("el-option", {
                        key: it.value,
                        attrs: { label: it.label, value: it.value }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    "label-width": "170px",
                    label: "支行行号",
                    prop: "unionBank"
                  }
                },
                [
                  _c("el-input", {
                    staticStyle: { width: "420px", display: "inline-block" },
                    attrs: { placeholder: "请输入", disabled: "" },
                    model: {
                      value: _vm.bindAccountForm.unionBank,
                      callback: function($$v) {
                        _vm.$set(_vm.bindAccountForm, "unionBank", $$v)
                      },
                      expression: "bindAccountForm.unionBank"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    "label-width": "170px",
                    label: "账户名称",
                    prop: "accountName"
                  }
                },
                [
                  _c("el-input", {
                    staticStyle: { width: "420px", display: "inline-block" },
                    attrs: { placeholder: "请输入" },
                    model: {
                      value: _vm.bindAccountForm.accountName,
                      callback: function($$v) {
                        _vm.$set(_vm.bindAccountForm, "accountName", $$v)
                      },
                      expression: "bindAccountForm.accountName"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    "label-width": "170px",
                    label: "账号",
                    prop: "cardNo"
                  }
                },
                [
                  _c("el-input", {
                    staticStyle: { width: "420px", display: "inline-block" },
                    attrs: { placeholder: "请输入" },
                    model: {
                      value: _vm.bindAccountForm.cardNo,
                      callback: function($$v) {
                        _vm.$set(_vm.bindAccountForm, "cardNo", $$v)
                      },
                      expression: "bindAccountForm.cardNo"
                    }
                  }),
                  _vm.type == 2
                    ? _c("div", { staticStyle: { color: "red" } }, [
                        _vm._v("企业用户必须绑定对公银行账户，否则无法提现")
                      ])
                    : _vm._e()
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    "label-width": "170px",
                    label:
                      _vm.type == 1
                        ? "银行预留手机号码"
                        : "对公账户法人手机号码",
                    prop: "phone"
                  }
                },
                [
                  _c("el-input", {
                    staticStyle: { width: "420px", display: "inline-block" },
                    attrs: { placeholder: "请输入" },
                    model: {
                      value: _vm.bindAccountForm.phone,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.bindAccountForm,
                          "phone",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "bindAccountForm.phone"
                    }
                  }),
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.showTite,
                          expression: "!showTite"
                        }
                      ],
                      staticClass: "getCode",
                      staticStyle: { color: "#666666" }
                    },
                    [_vm._v(_vm._s(this.count) + "s")]
                  ),
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.showTite,
                          expression: "showTite"
                        }
                      ],
                      staticClass: "getCode",
                      on: { click: _vm.getCode }
                    },
                    [_vm._v("获取验证码")]
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    "label-width": "170px",
                    label: "验证码",
                    prop: "verificationCode"
                  }
                },
                [
                  _c("el-input", {
                    staticStyle: { width: "420px", display: "inline-block" },
                    attrs: { placeholder: "请输入" },
                    model: {
                      value: _vm.bindAccountForm.verificationCode,
                      callback: function($$v) {
                        _vm.$set(_vm.bindAccountForm, "verificationCode", $$v)
                      },
                      expression: "bindAccountForm.verificationCode"
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "submitBox" },
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "submitBox-button",
                      attrs: { type: "primary", loading: _vm.subLoading },
                      on: {
                        click: function($event) {
                          _vm.submit()
                        }
                      }
                    },
                    [_vm._v("提交")]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-dialog",
            {
              attrs: {
                title: "新增支行信息",
                visible: _vm.dialogFormVisible,
                "close-on-click-modal": false,
                width: "750px"
              },
              on: {
                "update:visible": function($event) {
                  _vm.dialogFormVisible = $event
                },
                close: function($event) {
                  _vm.formClose()
                }
              }
            },
            [
              _c("div", { staticClass: "info-tips" }, [
                _vm._v(
                  "说明：1. 银行支行名称和支行行号需要自行在网上搜索，例如: 百度搜 工商银行宜春富民支行行号；"
                )
              ]),
              _c(
                "div",
                {
                  staticClass: "info-tips",
                  staticStyle: { margin: "0px 0px 20px 42px" }
                },
                [
                  _vm._v(
                    "2. 新增的银行支行名称和支行行号不能有误，否则无法绑定银行信息。"
                  )
                ]
              ),
              _c(
                "el-form",
                {
                  ref: "addForm",
                  attrs: { model: _vm.addForm, rules: _vm.addRules }
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        "label-width": "170px",
                        label: "开户行名称",
                        prop: "openBankName"
                      }
                    },
                    [
                      _c(
                        "el-select",
                        {
                          ref: "select",
                          staticStyle: {
                            width: "420px",
                            display: "inline-block"
                          },
                          attrs: {
                            filterable: "",
                            remote: "",
                            "reserve-keyword": "",
                            "remote-method": _vm.remoteOpenMethod,
                            placeholder: "根据开户行名称搜索",
                            clearable: ""
                          },
                          model: {
                            value: _vm.addForm.openBankName,
                            callback: function($$v) {
                              _vm.$set(_vm.addForm, "openBankName", $$v)
                            },
                            expression: "addForm.openBankName"
                          }
                        },
                        _vm._l(_vm.openBankList, function(it) {
                          return _c("el-option", {
                            key: it.label,
                            attrs: { label: it.label, value: it.label }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        "label-width": "170px",
                        label: "支行行名",
                        prop: "bankName"
                      }
                    },
                    [
                      _c("el-input", {
                        staticStyle: {
                          width: "420px",
                          display: "inline-block"
                        },
                        attrs: { placeholder: "请输入支行行名", clearable: "" },
                        model: {
                          value: _vm.addForm.bankName,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.addForm,
                              "bankName",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "addForm.bankName"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        "label-width": "170px",
                        label: "支行行号",
                        prop: "payBankNo"
                      }
                    },
                    [
                      _c("el-input", {
                        staticStyle: {
                          width: "420px",
                          display: "inline-block"
                        },
                        attrs: { placeholder: "请输入支行行号", clearable: "" },
                        model: {
                          value: _vm.addForm.payBankNo,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.addForm,
                              "payBankNo",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "addForm.payBankNo"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer"
                },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function($event) {
                          _vm.formClose()
                        }
                      }
                    },
                    [_vm._v("取 消")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function($event) {
                          _vm.infoSubmit()
                        }
                      }
                    },
                    [_vm._v("确 定")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }