<template>
    <div>
        <app-header title="绑定银行账户信息" :isShowBack="true"></app-header>
        <div class="base-info">
            <div class="info-title">{{bindTitle}}</div>
            <el-form ref="bindAccountForm" :model="bindAccountForm" :rules="rules">
                <el-form-item label-width="170px" label="开户行名称" prop="openBank">
                    <el-select style="width: 420px;display: inline-block;"  filterable remote reserve-keyword :remote-method="remoteOpenMethod" @change="onChangeSelectBank(bindAccountForm.openBank)"
                        v-model="bindAccountForm.openBank" ref="select1" placeholder="根据开户行名称搜索" @hook:mounted="cancalReadOnly" @visible-change="cancalReadOnly"
                        @clear="handleClearBank()" clearable>
                        <el-option v-for="it in openBankList" :key="it.label" :label="it.label" :value="it.label">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label-width="170px" label="支行行名" prop="branchBank">
                    <el-select style="width: 420px;display: inline-block;"  filterable remote reserve-keyword :remote-method="remoteMethod"
                        v-model="bindAccountForm.branchBank" ref="select" placeholder="根据支行行名搜索" @hook:mounted="cancalReadOnly" @visible-change="cancalReadOnly"
                        @change="onChangeSelect(bindAccountForm.branchBank)" @clear="handleClear()" clearable>
                        <el-option v-for="it in branchBankList" :key="it.value" :label="it.label" :value="it.value">
                        </el-option>
                    </el-select>
                    <!-- 暂时屏蔽 -->
                    <!-- <span class="info-btn" @click="dialogFormVisible = true">新增支行信息</span> -->
                </el-form-item>
                <!-- <el-form-item label-width="170px" label="支行行名" prop="branchBank">
                    <el-input style="width: 420px;display: inline-block;" placeholder="请输入"
                        v-model="bindAccountForm.branchBank">
                    </el-input>
                </el-form-item> -->
                <el-form-item label-width="170px" label="支行行号" prop="unionBank">
                    <el-input style="width: 420px;display: inline-block;" v-model="bindAccountForm.unionBank"
                        placeholder="请输入" disabled>
                    </el-input>
                </el-form-item>
                <el-form-item label-width="170px" label="账户名称" prop="accountName">
                    <el-input style="width: 420px;display: inline-block;" placeholder="请输入"
                        v-model="bindAccountForm.accountName">
                    </el-input>
                </el-form-item>
                <el-form-item label-width="170px" label="账号" prop="cardNo">
                    <el-input style="width: 420px;display: inline-block;" placeholder="请输入"
                        v-model="bindAccountForm.cardNo">
                    </el-input>
                    <div v-if="type==2" style="color: red">企业用户必须绑定对公银行账户，否则无法提现</div>
                </el-form-item>
                <el-form-item label-width="170px" :label="type==1?'银行预留手机号码':'对公账户法人手机号码'" prop="phone">
                    <el-input style="width: 420px;display: inline-block;" placeholder="请输入"
                        v-model.trim="bindAccountForm.phone">
                    </el-input>
                    <span v-show="!showTite" class="getCode" style="color: #666666;">{{ this.count }}s</span>
                    <span v-show="showTite" class="getCode" @click="getCode">获取验证码</span>
                </el-form-item>
                <el-form-item label-width="170px" label="验证码" prop="verificationCode">
                    <el-input style="width: 420px;display: inline-block;" placeholder="请输入"
                        v-model="bindAccountForm.verificationCode">
                    </el-input>
                </el-form-item>
                <div class="submitBox">
                    <el-button class="submitBox-button" type="primary" @click="submit()" :loading="subLoading">提交</el-button>
                </div>
            </el-form>

            <!-- 新增支行信息弹窗 -->
            <el-dialog title="新增支行信息" :visible.sync="dialogFormVisible" :close-on-click-modal=false width='750px' @close="formClose()">
                <div class="info-tips">说明：1. 银行支行名称和支行行号需要自行在网上搜索，例如: 百度搜 工商银行宜春富民支行行号；</div>
                <div class="info-tips" style="margin:0px 0px 20px 42px">2. 新增的银行支行名称和支行行号不能有误，否则无法绑定银行信息。</div>
                <el-form :model="addForm"  :rules="addRules" ref="addForm">
                    <el-form-item label-width="170px" label="开户行名称" prop="openBankName">
                        <el-select style="width: 420px; display: inline-block;"  filterable remote reserve-keyword :remote-method="remoteOpenMethod"
                            v-model="addForm.openBankName" ref="select" placeholder="根据开户行名称搜索" clearable>
                            <el-option v-for="it in openBankList" :key="it.label" :label="it.label" :value="it.label"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label-width="170px" label="支行行名" prop="bankName">
                        <el-input style="width: 420px;display: inline-block;" v-model.trim="addForm.bankName" placeholder="请输入支行行名" clearable></el-input>
                    </el-form-item>
                    <el-form-item label-width="170px" label="支行行号" prop="payBankNo">
                        <el-input style="width: 420px;display: inline-block;" v-model.trim="addForm.payBankNo" placeholder="请输入支行行号" clearable></el-input>
                    </el-form-item>
                </el-form>
                <div slot="footer" class="dialog-footer">
                    <el-button @click="formClose()">取 消</el-button>
                    <el-button type="primary" @click="infoSubmit()" >确 定</el-button>
                </div>
            </el-dialog>
        </div>
    </div>
</template>
<script>
    import Vue from "vue";
    const TIME_COUNT = 60; //更改倒计时时间
    let validMobile = (rule, value, callback) => {
        let reg = /^1[3-9](\d){9}$/;
        if (!reg.test(value)) {
            callback(new Error("手机号码格式不对"));
        } else {
            callback();
        }
    };
    let validBankName = (rule, value, callback) => {
        let reg = /^[\u4E00-\u9FA5]{0,64}$/;
        if (!value) {
            callback(new Error("请输入支行行名"));
        }else if (value.length > 64) {
            callback(new Error("支行行名不能超过64位"));
        }else if (!reg.test(value)) {
            callback(new Error("只能输入中文字符"));
        } else {
            callback();
        }
    };
    let validPayBankNo = (rule, value, callback) => {
        let reg = /^[1-9]\d{0,64}$/;
        if (!value) {
            callback(new Error("请输入支行行号"));
        }else if (value.length > 64) {
            callback(new Error("支行行号不能超过64位"));
        }else if (!reg.test(value)) {
            callback(new Error("只能输入数字"));
        } else {
            callback();
        }
    };
    export default {
        data() {
            return {
                pageCurrent: 1,
                pageSize: 50,
                pageNum: 1,
                loading: false,
                subLoading:false,
                type: 1,
                count: 0,
                timer: null,
                showTite: true,
                bindTitle: '绑定银行账户信息（用于收款的银行账户）',
                bindAccountForm: {
                    openBank: '',
                    branchBank: '',
                    unionBank: '',
                    accountName: '',
                    cardNo: '',
                    phone: '',
                    verificationCode: '',
                    bankCode: '',
                },
                tranceNum: '',
                openBankList: [],
                branchBankList: [],
                rules: {
                    openBank: [{
                        required: true,
                        message: "请输入开户行名称",
                        trigger: ['change', 'blur']
                    }],
                    branchBank: [{
                        required: true,
                        message: "请输入支行行名",
                        trigger: ['change', 'blur']
                    }],
                    unionBank: [{
                        required: true,
                        message: "请输入支行行号",
                        trigger: ['change', 'blur']
                    }],
                    accountName: [{
                        required: true,
                        message: "请输入账户名称",
                        trigger: ['change', 'blur']
                    }],
                    cardNo: [{
                        required: true,
                        message: "请输入账号",
                        trigger: ['change', 'blur']
                    }],
                    phone: [{
                        required: true,
                        validator: validMobile,
                        trigger: ['change', 'blur']
                    }],
                    verificationCode: [{
                        required: true,
                        message: "请输入验证码",
                        trigger: ['change', 'blur']
                    }],
                },
                userInfo: {},
                dialogFormVisible: false,
                addForm: {
                    openBankName: '',
                    bankName: '',
                    payBankNo: '',
                },
                addRules: {
                    openBankName: [{
                        required: true,
                        message: "请输入开户行名称",
                        trigger: ['change', 'blur']
                    }],
                    bankName: [{
                        required: true,
                        validator: validBankName,
                        trigger: ['change', 'blur']
                    }],
                    payBankNo: [{
                        required: true,
                        validator: validPayBankNo,
                        trigger: ['change', 'blur']
                    }],
                },
                bankCode: ''
            }
        },
        activated() {
            console.log('类型：', this.$route.query.type)
            this.type = this.$route.query.type;
            this.userInfo = this.cache.getLS('userInfo');
            if (this.type == 1) {
                // if(this.userInfo.roleType == 10) {
                //     this.bindTitle = '绑定银行账户信息（商家用于收款的银行账户）';
                // }else if(this.userInfo.roleType == 12) {
                //     this.bindTitle = '绑定银行账户信息（供应商用于收款的银行账户）';
                // }
                this.bindTitle = '绑定银行账户信息（用于收款的银行账户）';
            } else {
                // if(this.userInfo.roleType == 10) {
                //     this.bindTitle = '填写企业对公银行账户信息（商家用于提现的企业对公银行账户）';
                // }else if(this.userInfo.roleType == 12) {
                //     this.bindTitle = '填写企业对公银行账户信息（供应商用于提现的企业对公银行账户）';
                // }
                this.bindTitle = '填写企业对公银行账户信息（用于提现的企业对公银行账户）';
            }
            this.$refs["bindAccountForm"].resetFields();
            this.$refs.select.$refs.scrollbar.$refs.wrap.addEventListener('scroll', this.scolling)
            this.pageSize = 10;
            this.query = '';
            this.openQuery = ''
            // this.getBankList();
            this.branchBankList = [];
            this.openBankList = [];
        },
        mounted() {},
        deactivated() {
            this.count = '';
            this.showTite = true;
            clearInterval(this.timer); // 清除定时器
            this.timer = null;
        },
        methods: {
            scolling() {
                let e = this.$refs.select.$refs.scrollbar.$refs.wrap
                if (this.noMore) return
                // 到底时触发 loadMore
                let loadMore = e.scrollHeight - e.scrollTop <= e.clientHeight
                if (loadMore) {
                    this.loadMore()
                }
            },
            loadMore() {
                if (this.loading) {
                    return
                }
                this.getOpenBankList();
                this.getBankList();
            },
            getOpenBankList() {
                if(!this.openQuery) {
                    return
                }
                let dto = {
                    pageSize: this.pageSize,
                    pageCurrent: this.pageCurrent,
                    bankName: this.openQuery,
                };
                this.loading = true;
                let url = 'mall-service/merchant/v1/open/bank/list';
                this.post(url, dto).then(res => {
                    this.loading = false;
                    if (this.pageSize == res.total) {
                        this.$refs.select.$refs.scrollbar.$refs.wrap.removeEventListener('scroll', this
                            .scolling())
                        this.noMore = true
                    } else {
                        this.noMore = false;
                    }
                    this.pageSize = this.pageSize + 10;
                    res = JSON.parse(JSON.stringify(res).replace(/bankName/g, "label"));
                    this.openBankList = [];
                    this.openBankList = res;
                }).catch(err => {
                    this.loading = false;
                })
            },
            getBankList() {
                if(!this.query) {
                    return
                }
                let dto = {
                    pageSize: this.pageSize,
                    pageCurrent: this.pageCurrent,
                    bankName: this.query,
                };
                // let dto = {
                //     pageSize: this.pageSize,
                //     pageNum: this.pageNum,
                //     // titleName: '',//后续这里的入参是银行名称
                //     branchBank: this.query,
                // };
                this.loading = true;
                // let url = 'mall-service/v1/order-fp/get-page';
                let url = 'mall-service/merchant/v1/bank/page';
                this.post(url, dto).then(res => {
                    this.loading = false;
                    if (this.pageSize == res.total) {
                        // 获取到最后的值时，不再监听滚动条的动作，移除滚动事件
                        this.$refs.select.$refs.scrollbar.$refs.wrap.removeEventListener('scroll', this
                            .scolling())
                        this.noMore = true
                    } else {
                        this.noMore = false;
                    }
                    this.pageSize = this.pageSize + 10;
                    res.data = JSON.parse(JSON.stringify(res.data).replace(/bankName/g, "label"));
                    res.data = JSON.parse(JSON.stringify(res.data).replace(/id/g, "value"));
                   
                    this.branchBankList = [];
                    this.branchBankList = res.data;
                }).catch(err => {
                    this.loading = false;
                })
            },
            remoteOpenMethod(value) {
                this.pageSize = 50;
                this.pageCurrent = 1;
                this.loading = false;
                this.openQuery = value;
                this.loadMore();
            },
            remoteMethod(value) {
                this.pageSize = 50;
                this.pageCurrent = 1;
                //this.pageSize = 10;
                //this.pageNum = 1;
                this.loading = false;
                this.query = value;
                this.loadMore();
            },
            submitPersonal(dto) {
                dto.tranceNum = this.tranceNum;
                dto.bankCode = this.bindAccountForm.bankCode;
                this.$refs["bindAccountForm"].validate((valid) => {
                    if (valid) {
                        this.post('/mall-service/merchant/v1/bind/bankcard', dto, {
                            isUseResponse: true
                        }).then(res => {
                            if (res.data.code == 0) {
                                this.$message({
                                    message: '绑定成功！',
                                    type: 'success'
                                })
                                this.$back();
                            }
                            this.subLoading = false
                        }).catch(() => {
                            this.subLoading = false
                        })
                    } else {
                        this.subLoading = false
                    }
                })
            },
            getCodePersonal() {
                let dto = {
                    bizUserId: this.$route.query.id,
                    openBank: this.bindAccountForm.openBank,
                    branchBank: this.bindAccountForm.branchBank,
                    unionBank: this.bindAccountForm.unionBank,
                    accountName: this.bindAccountForm.accountName,
                    cardNo: this.bindAccountForm.cardNo.replace(/\s+/g,""),
                    phone: this.bindAccountForm.phone,
                    bankCode: this.bindAccountForm.bankCode,
                };
                
                if (!this.timer) {
                    this.post("/mall-service/merchant/v1/req/bind/bankcard", dto).then(res => {
                        this.tranceNum = res.data;

                        this.count = TIME_COUNT;
                        this.showTite = false;
                        this.timer = setInterval(() => {
                            if (this.count > 0 && this.count <= TIME_COUNT) {
                                this.count--;
                            } else {
                                this.showTite = true;
                                clearInterval(this.timer); // 清除定时器
                                this.timer = null;
                            }
                        }, 1000);
                        this.$message({
                            message: '发送成功！',
                            type: 'success',
                        })
                    }).catch(err => {})
                }
            },
            submitEnterprise(dto) {
                this.$refs["bindAccountForm"].validate((valid) => {
                    if (valid) {
                        this.post('/mall-service/merchant/v1/set/company', dto, {
                            isUseResponse: true
                        }).then(res => {
                            if (res.data.code == 0) {
                                this.$message({
                                    message: '绑定成功！',
                                    type: 'success'
                                })
                                this.$back();
                            }
                            this.subLoading = false
                        }).catch(() => {
                            this.subLoading = false
                        })
                    } else {
                        this.subLoading = false
                    }
                })
            },
            getCodeEnterprise() {
                let dto = {
                    mobile: this.bindAccountForm.phone,
                };
                if (!this.timer) {
                    this.post('/user-service/weChat/sendMobileCode', dto).then(res => {
                        this.count = TIME_COUNT;
                        this.showTite = false;
                        this.timer = setInterval(() => {
                            if (this.count > 0 && this.count <= TIME_COUNT) {
                                this.count--;
                            } else {
                                this.showTite = true;
                                clearInterval(this.timer); // 清除定时器
                                this.timer = null;
                            }
                        }, 1000);
                        this.$message({
                            message: '发送成功！',
                            type: 'success',
                        })
                    }).catch(err => {})
                }
            },
            submit() {
                this.subLoading = true
                let dto = {
                    bizUserId: this.$route.query.id,
                    openBank: this.bindAccountForm.openBank,
                    // branchBank: this.bindAccountForm.branchBank,
                    unionBank: this.bindAccountForm.unionBank,
                    accountName: this.bindAccountForm.accountName,
                    cardNo: this.bindAccountForm.cardNo.replace(/\s+/g,""),
                    phone: this.bindAccountForm.phone,
                    verificationCode: this.bindAccountForm.verificationCode,
                };
                this.branchBankList.forEach(item => {
                    if(item.value == this.bindAccountForm.branchBank) {
                        dto.branchBank = item.label;
                    }
                })
                if (this.type == 1) {
                    this.submitPersonal(dto);
                } else {
                    this.submitEnterprise(dto);
                }
            },
            getCode() {
                var reg = /^1[3-9](\d){9}$/;
                if (!this.bindAccountForm.phone) {
                    return this.$message({
                        message: '请输入手机号！',
                        type: "warning"
                    })
                } else if (reg.test(this.bindAccountForm.phone) == false) {
                    return this.$message({
                        message: '请输入正确的手机号！',
                        type: "warning"
                    })
                }
                if (!this.showTite) {
                    return
                }

                if (this.type == 1) {
                    this.getCodePersonal();
                } else {
                    this.getCodeEnterprise();
                }
            },
            onChangeSelect(value) {
                this.branchBankList.forEach(item => {
                    if(item.value == value) {
                        this.bindAccountForm.unionBank = item.payBankNo;
                    }
                })
            },
            handleClear() {
                this.bindAccountForm.unionBank = '';
            },

            onChangeSelectBank(value) {
                this.openBankList.forEach(item => {
                    if(value == item.label) {
                        this.bindAccountForm.bankCode = item.bankCode
                    }
                })
            },

            handleClearBank() {
                this.bindAccountForm.bankCode = '';
                this.bindAccountForm.openBank = '';
            },

            infoSubmit() {
                this.$refs["addForm"].validate((valid) => {
                    if(valid) {
                        let dto = {
                            bankName: this.addForm.bankName,
                            payBankNo: this.addForm.payBankNo,
                        }
                        this.openBankList.forEach(item =>{
                            if(item.label == this.addForm.openBankName) {
                                dto.bankCode = item.bankCode
                            }
                        })
                        this.post('/mall-service/merchant/v1/save/bank/info',dto).then(res => {
                            this.$message({
                                message: '提交成功！',
                                type: 'success',
                            })
                            this.formClose()
                        }).catch(err => {})
                    }
                })
            },
            formClose(){
                this.$nextTick(() => {
                    this.$refs['addForm'].resetFields()
                })
                this.dialogFormVisible = false
            },
            cancalReadOnly(onOff) {
                this.$nextTick(() => {
                    if (!onOff) {
                        const { select } = this.$refs;
                        const { select1 } = this.$refs;
                        const input = select.$el.querySelector('.el-input__inner');
                        const input1 = select1.$el.querySelector('.el-input__inner');
                        input.removeAttribute('readonly');
                        input1.removeAttribute('readonly');
                    }
                });
            }
        }
    }
</script>
<style lang="scss" scoped>
    .info-title {
        margin-left: 48px;
        padding-bottom: 36px;
    }
    .info-btn{
        color: #30BAC1;
        position: relative;
        left: 10px;
        cursor: pointer;
    }

    .getCode {
        color: #30BAC1;
        position: relative;
        left: 10px;
        cursor: pointer;
    }

    .submitBox {
        margin-left: 202px;
        margin-top: 20px;
    }

    .submitBox-button {
        width: 200px;
    }
    .dialog-footer{
        text-align: center;
    }
    /deep/.el-dialog__body{
        padding: 0 20px 30px!important;
    }
    .info-tips{
        padding: 2px;
        color: #999;
    }
</style>