import { render, staticRenderFns } from "./bindAccount.vue?vue&type=template&id=6c171be2&scoped=true&"
import script from "./bindAccount.vue?vue&type=script&lang=js&"
export * from "./bindAccount.vue?vue&type=script&lang=js&"
import style0 from "./bindAccount.vue?vue&type=style&index=0&id=6c171be2&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6c171be2",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/jenkins/data/workspace/wave-apartment-web-preview/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!module.hot.data) {
      api.createRecord('6c171be2', component.options)
    } else {
      api.reload('6c171be2', component.options)
    }
    module.hot.accept("./bindAccount.vue?vue&type=template&id=6c171be2&scoped=true&", function () {
      api.rerender('6c171be2', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/merchantManage/merchantInfo/bindAccount.vue"
export default component.exports